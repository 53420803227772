const env = process.env.REACT_APP_ENVIROMENT;
let url;
let qrRedirectUrl;
let sourceUrl = "sdsdds";
let amazonUrl = "https://hyperethertest.s3.eu-central-1.amazonaws.com";
let amazonUrlResized =
  "https://hyperethertestresized.s3.eu-central-1.amazonaws.com";

const googleMapsApiKey = "AIzaSyAJFa6Q0hRcZQlbaNOL4wgAcFLRqAMD__I";
const firebaseApiKey = "AIzaSyBYWH0jaEEeza8pVF4gHyEwnpK9YlBrcos";
let stripeKey =
  "pk_test_51J5rtCKN3W88XVfoJxWYoXQitH2ACz8xDPl0KZsIs6MQXVQRfMFIUd5iiepH2AIdYED9uTESfnqFZIi7Ka0prZCk00Zbd0GRa9";
let webAppUrl = "https://reusables-web.vercel.app";
if (env === "prod") {
  url = "https://api.reusables.com";
  qrRedirectUrl = "https://admin.reusables.com/#/redirect";
  stripeKey =
    "pk_live_51J9uW2Bb49XQ2DGyR5mBLrzaIv0FKqcPckguJ2poVuPxK0hYbJBhkqpT0WePH3dSynEwL4jZDltQ7NxlYzTK4wVG003Jnnt0Oa";
  webAppUrl = "https://app.reusables.com";
} else if (env === "staging") {
  url = "https://reusables.hyperether.com";
  qrRedirectUrl = "https://admin.reusables.hyperether.com/#/redirect";
} else {
  url = "http://localhost:4000";
  qrRedirectUrl = "http://localhost:3001/#/redirect";
}

export {
  url,
  sourceUrl,
  googleMapsApiKey,
  amazonUrl,
  amazonUrlResized,
  qrRedirectUrl,
  firebaseApiKey,
  stripeKey,
  webAppUrl,
};
